import React from 'react'
import { FcCallback } from "react-icons/fc";
import { SiMinutemailer } from "react-icons/si";
import { Link } from 'react-router-dom';
import { MdMyLocation } from "react-icons/md";
const ConatctUs = () => {
  return (
    <div className='lg:p-20 flex flex-col items-center justify-center lg:mb-28'>

     <h1 className='font-semibold text-5xl pb-4'> LET'S CHAT!</h1>
     <p className='mb-10'>We would Love to hear from You</p>
     <div className='lg:flex justify-between items-center w-full lg:px-40 px-2 gap-10'>
        <div className='flex items-center flex-col mb-10 lg:mb-0'>
            <FcCallback
            className='text-2xl mb-4'
            />
            <p>+90 533 820 15 27</p>
        </div>
        <div className='flex justify-center mx-auto items-center flex-col mb-10 lg:mb-0 '>
            <MdMyLocation
             className='text-2xl mb-4'
            />
            <Link className='mx-auto ' to="https://maps.app.goo.gl/bqMPM4WvaoDswtN58">https://maps.app.goo.gl/</Link>
        </div>
        <div className='flex items-center flex-col mb:10 lg:mb-0'>
            <SiMinutemailer
             className='text-2xl mb-4'
            />
            <p>marketxapp2024@gmail.com</p>
        </div>
       
       
     </div>
    </div>
  )
}

export default ConatctUs